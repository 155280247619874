<template>
    <div class="app">
        <div class="modal" v-if="showModal">
            <div class="modal-content">
                <div class="modal-header">
                    <img :src="require('@/assets/images/etnex_popup/icon_success.png')" alt="Success" class="modal-icon"/>
                </div>
                <div class="modal-body">           
                    <img :src="newpin_success" class="asset">
                    <h2 class="head_style2">{{ $t("newpin") }}</h2>
                    <p class="p_text mx-2">{{ $t("newpin2") }}</p>             
                </div>
                <div class="modal-footer">
                    <button @click="done_btn" class="btn-curved-wallet">{{$t("bthome") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            showModal: true,
            newpin_success: require("../../../assets/images/etnex_popup/newpin_success.png"),
        };
    },
    methods: {
        done_btn() {
            // this.$emit('closePopup');
            this.$router.go(-1);
        }
    },
    props: {
      message: {
           type: String,
           default: ''
      }
    },
};
</script>

  
<style>
.p_text {    
    color: var(--Main, #FFFFFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.h2_text {
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
}

.modal-body img{
    transform: translateX(0.5%) scale(1.1);
    
}

.modal-body{
    padding: 0rem;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    display: flex;
    width: 343px;
    padding: 80px 16px 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #222223;
    width: 400px;
    padding: 16px;
    padding-bottom: 0px;
    border-radius: 8px;
    text-align: center;
    gap: 16px;
    }

.modal-header img {
       
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
}

.modal-header {
    border-bottom: 0px !important;
}

.modal-footer {
    border-top: 0px !important;
    min-width: 100%;
}

.modal-body{
   margin-bottom: 20px !important; 
}

.done_button {
    width: 100%;
    color: var(--Dark-Purple, #251740);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background-image: url('../../../assets/images/etnex_home/colourful_bg.png');
}
  
</style>