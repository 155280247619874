<template>
  <div class="mainpage text" >
    <div class="">
      <b-row align-h="center">
        <b-col cols="11 mt-3">
          <b-form @submit.prevent="submitForm">
            
            <!-- <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <h5 class="label-form mb-2 text-14">
                  {{ $t("email") }}
                </h5>

                <b-input-group>
                    <b-form-input
                      v-model="email"
                      type="text"
                      readonly
                    ></b-form-input>
                    <b-input-group-append class="" style="position: relative">
                      <b-button
                        variant="primary"
                        :disabled="startCount || sending"
                        @click="getOTP"
                      >
                        <span v-if="!sending">{{ $t("getCode") }}</span
                        ><span v-else class="text-white">{{
                          $t("loading...")
                        }}</span>
                      </b-button>
                      <div
                        v-if="startCount"
                        class="text-center py-2 overlay-text"
                      >
                        {{ timecount }} s
                      </div>
                    </b-input-group-append>
                </b-input-group>                
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <h5 class="label-form mb-2 text-14">
                  {{ $t("vcode") }}
                </h5>
                <input type="text" class="form-control label-input" v-model="otp" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <h5 class="label-form mb-2 text-14">
                  {{ $t("new_sec_password") }}
                </h5>
                <input type="text" class="form-control label-input" v-model="password" required />
              </div>
            </div> -->

            <div class="form-group row">
                <div class="col-sm-12 mb-3">
                    <h5 class="label-form mb-2 text-14">{{ $t("new_secpwd") }}</h5>
                    <div class="input-group">
                        <input v-for="(digit, index) in digits" :key="index" type="number"
                            class="form-control label-input" :value="digit"
                            @input="updateDigit(index, $event.target.value)" min="0" max="9" pattern="[0-9]"
                            required maxlength="1" ref="inputFields" />
                    </div>
                </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                  <h5 class="label-form mb-2 text-14">{{ $t("confirm_new_secpwd") }}</h5>
                  <div class="input-group">
                      <input v-for="(digit, index) in confirmDigits" :key="`confirm-${index}`" type="number"
                          class="form-control label-input" :value="digit"
                          @input="updateConfirmDigit(index, $event.target.value)" min="0" max="9" pattern="[0-9]"
                          required maxlength="1" ref="confirmInputFields" />
                  </div>
              </div>
          </div>

            <b-button 
              type="submit" 
              class="mt-5 mb-5 btn-curved-wallet" 
              variant="primary" 
              block
              :disabled="isLoading">{{ isLoading ? $t("loading...") : $t("reset_pin") }}
            </b-button> 
            <Note v-if="showNote"/>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <Dialog ref="msg"></Dialog>
  </div>
</template>

<script>
import Note from "../popup/newpinsuccess.vue"
import { forgetSecPassword,getMemberInfo,requestOTP,checkOTP, } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
    // Note,
    fullPIN() {
        return this.digits.join('');
    }
  },
  components: {
    Dialog,
    
    // the Note need add in here
    Note,
  },
  data() {
    return {
      email: "",
      otp: "",
      timecount: 60,
      startCount: false,
      sec_password: "",
      password: "",
      password_confirmation: "",
      sending: false,
      isLoading: false,
      digits: ['', '', '', ''],
      confirmDigits: ['', '', '', ''],
      showNote : false,
    };
  },
  props: ["success"],
  methods: {
    updateDigit(index, value) {
        // Update the digit at the specified index
        this.digits[index] = value;

        // Move focus to the next input field
        if (index < this.digits.length - 1) {
            this.$refs.inputFields[index + 1].focus();
        }
    },
    updateConfirmDigit(index, value) {
      this.confirmDigits[index] = value;
      if (index < this.confirmDigits.length - 1) {
          this.$refs.confirmInputFields[index + 1].focus();
      }
  },
    getInfo() {
      var result = getMemberInfo();
      var self = this;
      this.isLoading = true;
      result
        .then(function (value) {
          self.isLoading = false;
          self.email = value.data.data.email;
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
    getOTP() {
      if (this.username == "") {
        this.$refs.msg.makeToast("danger", this.$t("emailEmpty"));
      } else {
        this.sending = true;
        this.isLoading = true;
        let formData = new FormData();
        var self = this;
        formData.append("otp_type", "email");
        var result = requestOTP(formData);

        result
          .then(function (value) {
            console.log(value.data);
            if (value.data.code == 0) {
              self.$refs.msg.makeToast("success", self.$t("otp_sent"));
              self.startCount = true;
              self.myVar = setInterval(() => {
                self.timecount -= 1;
                if (self.timecount == 0) {
                  self.timecount = 60;
                  clearInterval(self.myVar);
                  self.startCount = false;
                }
              }, 1000);
            } else {
              self.$refs.msg.makeToast("danger", self.$t(value.data.message));
            }
            self.sending = false;
            self.isLoading = false;
          })
          .catch(function (error) {
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
            self.sending = false;
            self.isLoading = false;
          });
      }
    },
    sendOTP() {
      let formData = new FormData();
      var self = this;
      formData.append("otp", this.otp);
      var result = checkOTP(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.code == 0) {
            self.$refs.msg.makeToast("success", self.$t(value.data.message));
            self.submitForm();
          } else {
            self.$refs.msg.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
    submitForm() {
      if (this.fullPIN !== this.confirmDigits.join('')) {
          this.$root.makeToast("danger", this.$t("pins_do_not_match"));
          return;
      }
      let formData = new FormData();
      var self = this;
      // formData.append("sec_password", this.sec_password);
      formData.append("password", this.fullPIN);
      formData.append("password_confirmation", this.fullPIN);
      var result = forgetSecPassword(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          if (value.data.code == 0) {
            // self.$root.makeToast("success", self.$t(value.data.message));
            // self.sec_password = "";
            self.password = "";
            self.password_confirmation = "";


            // if success make the self.showNote = true; will come out the popup
            self.showNote = true;


            // self.$router.push("/web/me/mePage");
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style>
.text{
  color: var(--Main, #FFF);
  font-family: Poppins;
  font-style: normal;
}

</style>