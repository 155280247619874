<template>
  <div class="main-content">
    <div class="position-relative">
      <div class="d-flex justify-content-center align-items-end pt-5">
        <h4 class="mt-5 mx-4 text-center text-white text-28">{{  $route.query.type == "pwd" ? $t("password_reset") : $t("reset_sec_password") }}</h4>
      </div>
        <div class="appBar">
          <a @click="$router.go(-1)">
            <i class="fa fa-chevron-left"></i>
          </a>
      </div>
    </div>
    <Pwd v-if="$route.query.type == 'pwd'"></Pwd>
    <SecPwd v-else-if="$route.query.type == 'sec_pwd'"></SecPwd>
  </div>
</template>

<script>
import Pwd from "./password.vue";
import SecPwd from "./sec_password.vue";
export default {
  components: {
    Pwd,
    SecPwd,
  },
};
</script>