<template>
  <div class="mainpage">
    <div class="">
      <b-row align-h="center">
        <b-col cols="11 mt-3">
          <b-form @submit.prevent="submitForm">

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <h5 class="label-form mb-2 text-14">
                  {{ $t("old_password") }}
                </h5>
              <input type="text" class="form-control label-input" v-model="old_password" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <h5 class="label-form mb-2 text-14">
                  {{ $t("new_password") }}
                </h5>
                <input type="text" class="form-control label-input" v-model="password" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 mb-3">
                <h5 class="label-form mb-2 text-14">
                  {{ $t("confirm_new_password") }}
                </h5>
                <input type="text" class="form-control label-input" v-model="password_confirmation" required />
              </div>
            </div>

            <b-button 
              type="submit" 
              class="mt-5 mb-5 btn-curved" 
              variant="primary" 
              block
              :disabled="isLoading">{{ isLoading ? $t("loading...") : $t("submit") }}
            </b-button>

            <ProfileSuccess v-if="showProfileSuccessModal" @closePopup="showProfileSuccessModal = false" :message="successMessage" />
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { changePassword } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import ProfileSuccess from '../popup/profilesuccess.vue';
export default {
  components: {
    ProfileSuccess,
  },
  data() {
    return {
      old_password: "",
      password: "",
      password_confirmation: "",
      sending: false,
      isLoading: false,
      showProfileSuccessModal: false,
      successMessage: '',
    };
  },

  methods: {

    closePopup() {
      this.showModal = false;
    },
    
    submitForm() {
      let formData = new FormData();
      var self = this;
      formData.append("old_password", this.old_password);
      formData.append("password", this.password);
      formData.append("password_confirmation", this.password_confirmation);
      var result = changePassword(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          if (value.data.code == 0) {
            // self.$root.makeToast("success", self.$t(value.data.message));
            self.successMessage = "success";
            self.showProfileSuccessModal = true;
            self.old_password = "";
            self.password = "";
            self.password_confirmation = "";
            // self.$router.push("/web/me/mePage");
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
  },
};
</script>